import React, { FC } from "react";
import { TabEntry, Tabs } from "components/tabs/tabs";
import { useWebConfigurationContext } from "context/web-configuration-context";
import { useTranslation } from "hooks/translation";

interface ClaimsTabsProps {
  tabId?: string;
  onChange: (tabId: string) => void;
}

export const ClaimsTabs: FC<ClaimsTabsProps> = ({ tabId, onChange }) => {
  const t = useTranslation();
  const webConfigurationContext = useWebConfigurationContext();
  const tabs: TabEntry[] = [
    { label: t({ tag: "claims.tab.case_data" }), tabId: "case_data" }
  ];

  if (
    webConfigurationContext?.webConfigurationContext.canUploadDocumentsLeaves
  ) {
    tabs.push({
      label: t({ tag: "claims.tab.documents" }),
      tabId: "documents"
    });
  }

  return (
    <Tabs
      tabId={tabId}
      tabs={tabs}
      onChange={tabId => {
        onChange(tabId);
      }}
    ></Tabs>
  );
};
