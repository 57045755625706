import { AbsenceType } from "features/report-absence/incident";
import { TimeInterval } from "shared-types/time";
import { LocaleTexts } from "shared-types/api";
import { min, parseISO } from "date-fns";
import { ClaimDate, MyAbilitiArClaim } from "graphql-types.gen";

export type FmlClaimData = {
  caseId: number;
  caseType: string;
  status?: LeaveClaimStatus;
  statusLocale?: LocaleTexts;
  condition: LocaleTexts;
  absenceType?: "continuous" | "intermittent" | "reduced-hours";
  decisions: ClaimDecision[];
  policies: ClaimPolicy[];
  leaveStatusLocale?: LocaleTexts;
  claimTypeLocale?: LocaleTexts;
  title?: LocaleTexts;

  // Presagia questions
  pertainsTo?: ClaimRelationship;
  requestDate?: Date;
};

export type AbsenceClaimData = {
  associatedLeaveCaseId: number;
  associatedSTDCaseId: number;
  claimNumber: string;
  claimStatus: AbsenceClaimStatus;
  claimStatusLocale: LocaleTexts;
  caseType: string;
  status: string;
  expectedRTWDate: Date;
  firstDateOfAbsence: Date;
  lastDateOfAbsence: Date;
  leaveIncidentId: number;
  caseState: string;
  primaryReasonLocale: LocaleTexts;
  secondaryReasonLocale: LocaleTexts;
  incidentId: number;
  absenceDates: ClaimDate[];
  absenceType: string;
  returnToWorkDate: Date;
};

export const AbsenceClaimStatuses = [
  "in-progress",
  "submitted",
  "completed",
  "incomplete"
] as const;
export const StdClaimStatuses = ["open", "closed"] as const;
export const LeaveClaimStatuses = [
  "approved",
  "pending",
  "pending-review",
  "pending-paperwork",
  "cancelled",
  "denied",
  "missing-paperwork",
  "closed",
  "outcomed"
] as const;

export type AbsenceClaimStatus = typeof AbsenceClaimStatuses[number];
export type StdClaimStatus = typeof StdClaimStatuses[number];
export type LeaveClaimStatus = typeof LeaveClaimStatuses[number];

export type DecisionStatus = "APPROVED" | "DENIED" | "PENDING";

export type ClaimDecision = {
  start: Date;
  end: Date;
  status: DecisionStatus;
  policyCode?: string;
  policyCodeText?: LocaleTexts;
};

export type ClaimPolicy = {
  used: number;
  unit?: "hours" | "days" | "weeks" | "months";
  planned: number;
  policyCode: string;
  policyCodeText: LocaleTexts;
};

export type StdCaseData = {
  caseId: number;
  title: LocaleTexts;
  startDate?: Date;
  firstDateOfAbsence?: Date;
  status?: StdClaimStatus;
  statusLocale?: LocaleTexts;
  decisions: ClaimDecision[];
};

export const ClaimRelationships = [
  "child-adopted",
  "child-biological",
  "child-adult",
  "child-partner",
  "child-foster",
  "child-step",
  "in-loco-parentis",
  "legal-ward",
  "next-of-kin",
  "parent",
  "parent-step",
  "self",
  "spouse"
] as const;
export type ClaimRelationship = typeof ClaimRelationships[number];

export const ClaimTypes = ["fml", "std", "absence", "wcb"] as const;
export type ClaimType = typeof ClaimTypes[number];
export const LeaveTypeCodes = [
  "medicalOccupational",
  "medicalNonOccupational"
] as const;
export type LeaveTypeCode = typeof LeaveTypeCodes[number];

export type SearchClaims = {
  incidentId: number;
  createdBy: MyAbilitiArClaim["createdBy"];
  title: LocaleTexts;
  absenceDates: TimeInterval[];
};

type ClaimSummaryBase = Readonly<{
  incidentId: number;
  title: LocaleTexts;
  relationship?: ClaimRelationship;
  isReferral: boolean;
}>;

export type ClaimSummary = Readonly<
  ClaimSummaryBase &
    (
      | {
          claimType: "fml";
          status: LeaveClaimStatus;
          statusLocale?: LocaleTexts;
          startDate?: Date;
          conditionCategoryLocale?: LocaleTexts;
          leaveTypeCode?: LeaveTypeCode;
          primaryReasonOfAbsenceLocale?: LocaleTexts;
          secondaryReasonOfAbsenceLocale?: LocaleTexts;
        }
      | {
          claimType: "std";
          status: StdClaimStatus;
          startDate?: Date;
          firstDateOfAbsence?: Date;
        }
      | {
          claimType: "absence";
          absenceType: AbsenceType;
          absenceDates: TimeInterval[];
          status: AbsenceClaimStatus;
        }
      | {
          claimType: "incomplete";
          absenceType: AbsenceType | undefined;
          absenceDates: TimeInterval[];
          status: AbsenceClaimStatus;
          createdBy: string | undefined | null;
        }
    )
>;

export type ClaimSummaryWithTranslatedRelationship = Readonly<
  ClaimSummary & {
    translatedRelationship: string;
  }
>;

export type ClaimListIncidentType = {
  incidentTypeCode: string;
  headerText: LocaleTexts;
  order: number;
};

export function firstDateOfAbsence(claim: ClaimSummary): Date | undefined {
  switch (claim.claimType) {
    case "fml":
    case "std":
      return claim.startDate;

    case "absence":
      return min(claim.absenceDates.map(d => parseISO(d.date)));
  }
}

export type LeavePolicy = {
  policyCode?: string;
  unit?: string;
  remaining?: number;
  policyCodeLocale: LocaleTexts;
  total?: number;
  eligibility?: boolean;
};
