import React from "react";
import { ClaimSummary } from "features/claims/claims-model";
import { getClaimsService } from "features/claims/claims-service";
import { getSessionService } from "features/session/session-service";
import { useApiRequest, useDesktopBreakpoint } from "hooks";
import { useTranslation } from "hooks/translation";
import { StandardPage } from "page-templates/standard";
import {
  ClaimDesktopHeaderFragment,
  MobileHeaderFragment
} from "pages/claims/helpers";
import { LeavePolicy as LeavePolicyRoute } from "routes";
import { useRouteParams } from "routes/definition";
import styles from "./leave-policy.module.scss";
import { ClaimCard } from "components/claim-card/claim-card";
import { LoadingSpinner } from "components/loading-spinner";
import { ApiErrorView } from "components/api-error/api-error-view";
import { LeavePolicy } from "features/claims/claims-model";
import { PolicyRemainingPeriod } from "components/policy-remaining-period/policy-remaining-period";
import { Translation } from "components/translation";

export const LeavePolicyPage: React.FunctionComponent = () => {
  const params = useRouteParams(LeavePolicyRoute);
  const t = useTranslation();
  const isDesktop = useDesktopBreakpoint();
  const sessionService = getSessionService();

  const {
    isLoading: isLoadingPolicy,
    data: leavePolicy,
    error: policyError
  } = useApiRequest<LeavePolicy | undefined>(async () => {
    return getClaimsService()
      .getLeavePolicySummary()
      .then(policies =>
        policies.find(policy => policy.policyCode === params.policyCode)
      );
  }, []);

  const {
    isLoading: isLoadingClaims,
    data: claims,
    error: claimsError
  } = useApiRequest<ClaimSummary[]>(
    async () =>
      leavePolicy
        ? getClaimsService()
            .listClaims(sessionService.getUser()?.ee_no || "", [
              params.policyCode
            ])
            .then(result => result.claims)
        : [],
    [leavePolicy]
  );

  return (
    <StandardPage
      getHeaderFlexItems={ctx =>
        ctx.isDesktop ? (
          <ClaimDesktopHeaderFragment />
        ) : (
          <MobileHeaderFragment>
            <div className={styles["header-mobile"]}>
              {t(leavePolicy?.policyCodeLocale)}
            </div>
          </MobileHeaderFragment>
        )
      }
      mobileHeaderWithBorder={true}
    >
      <div className={styles["leave-policy"]}>
        {isLoadingPolicy || policyError ? (
          <div className={styles["spinner"]}>
            {isLoadingPolicy ? <LoadingSpinner /> : <ApiErrorView />}
          </div>
        ) : (
          <>
            {isDesktop && leavePolicy && (
              <div className={styles["title"]}>
                {t(leavePolicy?.policyCodeLocale)}
              </div>
            )}
            {leavePolicy && (
              <div className={styles["remaining-time-box"]}>
                <div className={styles["content-text"]}>
                  <span className={styles["remaining"]}>
                    {leavePolicy?.remaining}
                  </span>
                  <PolicyRemainingPeriod
                    unit={leavePolicy?.unit ?? ""}
                    remaining={leavePolicy?.remaining ?? 0}
                  ></PolicyRemainingPeriod>
                </div>
              </div>
            )}
          </>
        )}

        {isLoadingClaims || claimsError ? (
          <div className={styles["spinner"]}>
            {isLoadingClaims ? <LoadingSpinner /> : <ApiErrorView />}
          </div>
        ) : (
          <>
            {leavePolicy && !policyError && !isLoadingPolicy && (
              <>
                <div className={styles["claims-list-header"]}>
                  {t({
                    tag: "leave-policy.claims_list_header",
                    vars: {
                      policyName: t(leavePolicy?.policyCodeLocale)
                    }
                  })}
                </div>
                {(claims ?? []).length > 0 && (
                  <div className={styles["claims-list"]}>
                    {claims?.map(claim => (
                      <ClaimCard
                        key={`${claim.claimType}-${claim.incidentId}`}
                        className={styles["claim-card"]}
                        claim={claim}
                      />
                    ))}
                  </div>
                )}
                {claims?.length === 0 && (
                  <div className={styles["empty-cases"]}>
                    <img
                      className={styles["icon"]}
                      src={
                        process.env.REACT_APP_ASSETS_BASE +
                        "/images/claims/folder-empty-state.svg"
                      }
                    ></img>
                    <h3 className={styles["head"]}>
                      {t({
                        tag: "leave-policy.no_claims",
                        vars: {
                          policyName: t(leavePolicy?.policyCodeLocale)
                        }
                      })}
                    </h3>
                    <h4 className={styles["context"]}>
                      <Translation tag={"leave-policy.no_claims_subtext"} />
                    </h4>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </StandardPage>
  );
};
