import { getApiGraphQL } from "./api-graphql";
import { gql } from "@apollo/client";

import {
  ClientFeature,
  IWebConfigurationService,
  WebConfiguration
} from "features/config/web-configuration-model";
import {
  CanAccessApplicationQuery,
  ClientFeatureUrlQuery
} from "graphql-types.gen";

export type WebConfigurationQuery = {
  webConfigByUrl?: WebConfiguration;
};

export class ApiWebConfigurationService implements IWebConfigurationService {
  getWebConfigurationByUrl = async (url: string): Promise<WebConfiguration> =>
    new Promise<WebConfiguration>((resolve, reject) => {
      getApiGraphQL()
        .query<WebConfigurationQuery>({
          query: gql`
            query web($url: String) {
              webConfigByUrl(url: $url) {
                clientCode
                instanceId
                logoFile {
                  content
                  fileName
                  mimeType
                }
                canUploadDocumentsLeaves
              }
            }
          `,
          variables: {
            url: url
          }
        })
        .then(result => {
          if (result.data && result.data.webConfigByUrl) {
            resolve(result.data.webConfigByUrl);
          } else reject(result);
        })
        .catch(error => {
          reject(error);
        });
    });

  canAccessApplication = async (clientCode: string) =>
    new Promise<boolean>((resolve, reject) => {
      getApiGraphQL()
        .query<CanAccessApplicationQuery>({
          query: gql`
            query CanAccessApplication($clientCode: String) {
              canAccessApplication(
                application: MYABILITI_PORTAL
                clientCode: $clientCode
              )
            }
          `,
          variables: {
            clientCode
          }
        })
        .then(result => {
          if (result.data) {
            resolve(result.data.canAccessApplication);
          } else {
            reject(result);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  getClientFeatureUrl = async (
    clientCode: string,
    feature: ClientFeature,
    language: string
  ) =>
    new Promise<string>((resolve, reject) => {
      getApiGraphQL()
        .query<ClientFeatureUrlQuery>({
          query: gql`
            query clientFeatureUrl(
              $clientCode: String
              $featureName: ClientFeatureName!
              $language: String
            ) {
              clientFeatureUrl(
                clientCode: $clientCode
                featureName: $featureName
                language: $language
              )
            }
          `,
          variables: {
            clientCode,
            featureName: feature === "connect" ? "CLAIMSLIST" : null,
            language
          }
        })
        .then(result => {
          if (result.data) {
            resolve(result.data.clientFeatureUrl as string);
          } else {
            reject(result);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
}
