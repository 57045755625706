import React from "react";
import { LeavePolicy } from "features/claims/claims-model";
import { useTranslation } from "hooks/translation";
import styles from "./policy.module.scss";
import { PolicyRemainingPeriod } from "components/policy-remaining-period/policy-remaining-period";

type Props = {
  policy: LeavePolicy;
};

export const Policy: React.FC<Props> = ({ policy }) => {
  const t = useTranslation();

  return (
    <div className={styles["policy"]}>
      <div className={styles["name"]}>{t(policy.policyCodeLocale)}</div>
      <div className={styles["days-left"]}>
        <span className={styles["number"]}> {policy.remaining} </span>
        <PolicyRemainingPeriod
          unit={policy.unit ?? ""}
          remaining={policy.remaining ?? 0}
        ></PolicyRemainingPeriod>
      </div>
      <a href={`leave-policy/${policy.policyCode}`} className={styles["link"]}>
        {t({ tag: "dashboard.view" })}
      </a>
    </div>
  );
};
