import React, { useState, useContext, useEffect } from "react";
import { useSessionStorage } from "hooks";
import { getWebConfigurationService } from "features/config/web-configuration-service";

type webConfigurationType = {
  clientCode?: string;
  instanceId?: string;
  logoFile?: string;
  canUploadDocumentsLeaves?: boolean;
};

interface webConfigurationStateType {
  webConfigurationContext: webConfigurationType;
}

const webConfigurationInitialState: webConfigurationType = {};

const WebConfigurationContext = React.createContext<
  webConfigurationStateType | undefined
>(undefined);

export const WebConfigurationContextProvider: React.FunctionComponent = props => {
  // Persist to sessionStorage as well so that state is not lost if the user reloads the page
  const [
    storedSession,
    setStoredContext
  ] = useSessionStorage<webConfigurationType>(
    "webConfigurationContext",
    webConfigurationInitialState
  );
  const [sessionState] = useState<webConfigurationType>(storedSession);

  const fetchConfigdata = async (url: string): Promise<void> => {
    if (storedSession == null || storedSession.clientCode == null) {
      await getWebConfigurationService()
        .getWebConfigurationByUrl(url)
        .then(result => {
          setStoredContext({
            clientCode: result.clientCode,
            instanceId: result.instanceId,
            logoFile: result.logoFile && result.logoFile.content,
            canUploadDocumentsLeaves: result.canUploadDocumentsLeaves
          });
        });
    }
  };

  useEffect(() => {
    //load the web configuration
    fetchConfigdata(window.location.origin).catch(err => {
      console.error(err);
    });
  });

  return (
    <WebConfigurationContext.Provider
      value={{ webConfigurationContext: sessionState }}
    >
      {props.children}
    </WebConfigurationContext.Provider>
  );
};

export const useWebConfigurationContext: () => webConfigurationStateType = () => {
  const context = useContext(WebConfigurationContext);
  if (context === undefined) {
    throw new Error("webConfigurationContext requires a Provider");
  }
  return context;
};
