import React, { useState } from "react";
import { ViewStdClaim } from "routes";
import { useRouteParams } from "routes/definition";
import { LoadingSpinner } from "components/loading-spinner";
import { orderBy } from "lodash-es";
import { TagForClaimStatus } from "components/tag/tag-for-claim-status";
import { Tag } from "components/tag/tag";
import {
  ClaimDesktopHeaderFragment,
  ClaimMobileHeaderFragment,
  DecisionsSection,
  DesktopClaimIdLabel
} from "./helpers";
import FramedIcon from "components/icons/framed-icon/framed-icon";
import { CalendarIcon } from "components/icons";
import SummaryLabel from "components/summary-label/summary-label";
import styles from "./styles.module.scss";
import stdStyles from "./std-claim.module.scss";
import { ClaimDecision, StdCaseData } from "features/claims/claims-model";
import { useApiRequest } from "hooks";
import { StandardPage } from "page-templates/standard";
import { Translation } from "components/translation";
import { getClaimsService } from "features/claims/claims-service";
import { useTranslation } from "hooks/translation";
import { ApiErrorView } from "components/api-error/api-error-view";
import { ClaimsTabs } from "./claim-tabs";
import { DocumentUpload } from "../../components/documents/document-upload";
import { DocumentEntityType } from "components/documents/documents";
import { useFormat } from "hooks/format";

const StdClaimPage: React.FC = () => {
  const params = useRouteParams(ViewStdClaim);
  const t = useTranslation();

  const caseId = parseInt(params.claimId, 10);

  const { data: stdCase, error, isLoading } = useApiRequest(
    async () => getClaimsService().loadStdClaim(caseId),
    [params.claimId]
  );

  const [activeTab, setActiveTab] = useState("case_data");

  return (
    <StandardPage
      getHeaderFlexItems={({ isDesktop }) =>
        isDesktop ? (
          <ClaimDesktopHeaderFragment />
        ) : (
          <ClaimMobileHeaderFragment caseId={params.claimId} />
        )
      }
      fixedPaneContents={
        stdCase && (
          <>
            <div>
              <div className={styles["tag-row"]}>
                <Tag tagStyle="blue">
                  <Translation tag={"claims.tag.std"} />
                </Tag>
                {stdCase.status && (
                  <TagForClaimStatus status={stdCase.status} />
                )}
              </div>
              <h1 className={styles["page-title"]}>{t(stdCase.title)}</h1>
            </div>

            <DesktopClaimIdLabel claimId={caseId.toString()} />

            <ClaimsTabs
              tabId={activeTab}
              onChange={tabId => {
                setActiveTab(tabId);
              }}
            ></ClaimsTabs>
          </>
        )
      }
    >
      {isLoading || error ? (
        <>{isLoading ? <LoadingSpinner /> : <ApiErrorView />}</>
      ) : (
        stdCase && <StdClaimContent claim={stdCase} tabId={activeTab} />
      )}
    </StandardPage>
  );
};

const StdClaimContent: React.FC<{ claim: StdCaseData; tabId: string }> = ({
  claim,
  tabId
}) => {
  const { formatLocal } = useFormat();
  const [showAllDecisions, setShowAllDecisions] = useState(false);

  const sortedDecisions: ClaimDecision[] = orderBy(
    claim.decisions,
    ["start"],
    "desc"
  );

  return (
    <>
      {tabId === "case_data" && (
        <div>
          {claim.startDate && (
            <>
              <div className={styles["left-and-right"]}>
                <h3 className={styles["mini-header"]}>
                  <Translation tag={"claims.label.std_start_date"} />
                </h3>
              </div>

              <div className={stdStyles["start-date-container"]}>
                <FramedIcon icon={CalendarIcon} className={stdStyles["icon"]} />
                <SummaryLabel
                  label={formatLocal(claim.startDate, "iiii")}
                  value={formatLocal(claim.startDate, "MMM d, yyyy")}
                />
              </div>
            </>
          )}

          <DecisionsSection
            showAll={showAllDecisions}
            decisions={sortedDecisions}
            setShowAllDecisions={setShowAllDecisions}
          />
        </div>
      )}
      {tabId === "documents" && (
        <DocumentUpload
          entityId={claim.caseId}
          entityType={DocumentEntityType.Case}
        />
      )}
    </>
  );
};

export default StdClaimPage;
